<template>
  <section class="container">
    <div class="container">
      <b-loading
        v-if="loading"
        :is-full-page="true"
        v-model="loading"
        :can-cancel="false"
      ></b-loading>
      <div v-else class="columns cards-container" id="sectioncontainer">
        <div class="column is-one-third is-narrow">
          <article class="message is-warning is-light">
            <div class="message-header">
              <p>
                Tickets abertos sem atendente ({{
                  lists[`open_tickets`] ? lists[`open_tickets`].length : ""
                }})
              </p>
              <!-- <button class="delete" aria-label="delete"></button> -->
            </div>
            <draggable
              ghost-class="ghost-card"
              id="open_tickets"
              class="message-body"
              :list="lists[`open_tickets`]"
              group="people"
              @start="startCallback"
              @end="endCallback"
              :move="onMoveCallback"
            >
              <div
                class="board-item"
                v-for="ticket in lists[`open_tickets`]"
                :key="ticket.id"
              >
                <ticket-card v-on:openTicketDetails="openTicketDetails" :ticket="ticket" />
              </div>
            </draggable>
          </article>
        </div>
        <div
          class="column is-one-third is-narrow"
          v-for="attendant in allAttendants"
          :key="attendant.id"
        >
          <article class="message is-info">
            <div class="message-header">
              <p>
                {{ attendant.name }} ({{ lists[`${attendant.id}`] ? lists[`${attendant.id}`].length : '' }})
              </p>
            </div>
            <draggable
              ghost-class="ghost-card"
              :id="attendant.id"
              class="message-body"
              :list="lists[`${attendant.id}`]"
              group="people"
              @start="startCallback"
              :move="onMoveCallback"
              @end="endCallback"
            >
              <div
                class="board-item"
                v-for="ticket in lists[`${attendant.id}`]"
                :key="ticket.id"
              >
                <ticket-card v-on:openTicketDetails="openTicketDetails" :ticket="ticket" />
              </div>
            </draggable>
          </article>
        </div>
      </div>
      <ModalTicketMessages
        v-if="isModalTicketDetailsOpen"
        :ticket="selectedTicket"
        v-on:closeModal="closeModal"
        :isComponentModalActive="isModalTicketDetailsOpen"
      />
    </div>
  </section>
</template>
<script>
import draggable from 'vuedraggable'
import { mapActions, mapGetters } from 'vuex'
import { get } from '../utils/api'
import mixin from '../utils/mixins'
import TicketCard from '../components/tickets/TicketCard.vue'
import ModalTicketMessages from '@/components/modals/ModalTicketMessages'

export default {
  name: 'two-lists',
  display: 'Two Lists',
  order: 1,
  components: {
    draggable,
    TicketCard,
    ModalTicketMessages
  },
  mixins: [mixin],
  data () {
    return {
      lists: {},
      list1Copy: null,
      list2Copy: null,
      ticketToTransfer: null,
      loading: false,
      isModalTicketDetailsOpen: false,
      selectedTicket: null
    }
  },
  computed: {
    ...mapGetters(['allAttendants', 'fetchingAttendants', 'allDepartments'])
  },
  async mounted () {
    this.loading = true
    await this.fetchAttendants()
    this.configLists()
    const response = await this.fetchTickets()
    this.fillLists(response.data)
    this.loading = false
  },
  methods: {
    ...mapActions([
      'addAttendant',
      'updateAttendant',
      'fetchAttendants',
      'deleteAttendant',
    ]),
    openTicketDetails (ticket) {
      this.selectedTicket = ticket
      this.isModalTicketDetailsOpen = true
    },
    closeModal () {
      this.isModalTicketDetailsOpen = false
    },
    configLists () {
      this.allAttendants.map((attendant) => {
        this.lists[`${attendant.id}`] = []
      })
      this.lists.open_tickets = []
    },
    fillLists (tickets) {
      tickets.map((ticket) => {
        if (ticket.attendant_id) {
          this.lists[`${ticket.attendant_id}`].push(ticket)
        } else {
          this.lists.open_tickets.push(ticket)
        }
      })
    },
    startCallback () {
      this.list1Copy = JSON.parse(JSON.stringify(this.list1 ?? []))
      this.list2Copy = JSON.parse(JSON.stringify(this.list2 ?? []))
    },
    resetToCopy () {
      this.list1 = JSON.parse(JSON.stringify(this.list1Copy))
      this.list2 = JSON.parse(JSON.stringify(this.list2Copy))
      this.list1Copy = null
      this.list2Copy = null
    },
    async fetchTickets () {
      const r = await get('tickets')
      return r
    },
    endCallback () {
      if (!this.ticketToTransfer) {
        this.resetToCopy()
        return
      }

      this.$buefy.dialog.confirm({
        title: 'Transferencia de ticket',
        message: `Deseja transferir o ticket ${this.ticketToTransfer.ticket_id} para o atendente ${this.ticketToTransfer.attendant_id}?`,
        confirmText: 'Sim',
        type: 'is-primary',
        hasIcon: true,
        cancelText: 'Não',
        // onConfirm: () => this.closeTicket(roomId, true),
        onCancel: () => this.resetToCopy()
      })
    },
    onMoveCallback: function (evt, originalEvent) {
      if (evt.to.id === 'open_tickets' || evt.to.id === evt.from.id) {
        this.ticketToTransfer = null
        return false
      }

      this.ticketToTransfer = {
        attendant_id: evt.to.id,
        ticket_id: evt.draggedContext.element.id
      }
    }
  }
}
</script>

<style scoped>
#sectioncontainer {
  margin: 20px;
}

/* Keep from sticking to sides */
.cards-container {
  max-width: 95%;
  overflow-x: scroll;
}

.message-body {
  height: calc(100vh - 200px);
  overflow-y: scroll;
}

.board-item {
  margin: 5px 0;
  will-change: transform;
}

.board-item-content {
  word-break: break-all;
  position: relative;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  font-size: 17px;
  /* text-align: center; */
  cursor: move;
  -webkit-box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.2);
  margin: 5px;
}

.delete {
  pointer-events: auto;
}

footer {
  margin-top: 2rem;
}

#sectioncontainer {
  background-color: #f4f5f7;
}

.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4299e1;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>
