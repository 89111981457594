<template>
  <div class="board-item-content">
    <p>
      {{ ticket.id }} - {{ ticket.contact ? ticket.contact.name : ticket.client_name }}
      <!-- <span
        v-if="ticket.is_group"
        class="tag is-info is-pulled-right is-light"
        >Grupo</span
      > -->
    </p>
    <p>
      {{ formatDate(ticket.created_at) }}
      <span class="is-pulled-right is-light">{{
        formatTime(ticket.created_at)
      }}</span>
    </p>
    <p class="w-100">
      <span
        v-if="ticket.is_group"
        class="tag is-info is-fullwidth is-light w-100"
        >Grupo</span
      >
    </p>
    <p class="w-100">
      <span
        v-if="ticket.department"
        class="tag is-success is-fullwidth is-light w-100"
        >{{ ticket.department.id }} - {{ ticket.department.name }}</span
      >
      <span v-else class="tag is-fullwidth is-light w-100"
        >Sem departamento</span
      >
    </p>
    <hr />
    <p>
      <button class="button is-link is-small is-fullwidth" @click="openTicketDetails">
        Acompanhar atendimento
      </button>
      <button class="mt-1 button is-small is-fullwidth">Fechar ticket</button>
    </p>
  </div>
</template>

<script>
import mixin from '../../utils/mixins'

export default {
  mixins: [mixin],
  props: {
    ticket: {
      require: true,
      type: Object
    }
  },
  methods: {
    openTicketDetails () {
      this.$emit('openTicketDetails', this.ticket)
    }
  }
}
</script>

<style scoped>
.w-100 {
  width: 100%;
}
</style>
